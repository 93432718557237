<template>
  <div style="line-height: 0.375rem !important;">
    <v-row no-gutters v-if="documentLoading">
      <v-col v-for="n in 5" :key="n" cols="12">
        <v-skeleton-loader class="mx-auto"  type="list-item-avatar"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-list flat class="tile--list documents pt-0 rounded-xl" v-else-if="listOfRecentDocuments.length > 0 && !documentLoading" dense style="max-height:373px; overflow: auto;">
      <v-list-item-group active-class="primary--text">
        <template v-for="(document, index) in listOfRecentDocuments">
          <v-list-item :key="`${index}_name`" @click="navigate(document)">
            <v-icon :size="20" class="pr-2" dark :color="$formatter.mimeTypeOfDocument(document.mimetype).color">{{ $formatter.mimeTypeOfDocument(document.mimetype).icon }}</v-icon>
            <v-list-item-content>
              <v-list-item-subtitle class="text--primary" v-text="document.title"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text>{{ $formatter.getHumanzieTime(document.lastmodifiedtime, $i18n.locale === 'no' ? 'nb' : $i18n.locale) }}</v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index + 1 < listOfRecentDocuments.length" :key="index"></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
    <v-alert class="mx-3 mt-2 alertbox" color="info" dark icon="mdi-cloud-alert" border="left" prominent outlined v-else>
      {{ $t('noDocuments') }}
    </v-alert>
  </div>
</template>
<script>
export default {
  props: ['listOfRecentDocuments', 'documentLoading'],
  methods: {
    navigate (document) {
      if (document && document.path) window.open(document.path, '_blank')
      // this.$api.execute('get', `sharepoint/get_document/${document.id}`).then(response => {
      //   if (response && response.data) {
      //     let url = ''
      //     if (document.path) url = response.data.path
      //     else url = response.data.weburl
      //     window.open(url, '_blank')
      //   }
      // })
    }
  }
}
</script>
<style>
.documents .v-list-item--dense, .v-list--dense .v-list-item {
  min-height: 30px !important;
}
.alertbox .v-alert__content{
  line-height: 1.375rem !important;
}
</style>
